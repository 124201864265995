import { useState, useEffect } from "react";
import "./DealsGridView.css";
import DealGridItemView from "./DealGridItemView";
import LoadingDealGridView from "./LoadingDealGridView";

function DealsGridView({ onClickItem }) {
  const [deals, setDeals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const apiUrl =
      "https://us-central1-red-flag-or-deal-breaker.cloudfunctions.net/rfdb/pp";
    fetch(apiUrl, { headers: { "Access-Control-Allow-Origin": "*" } })
      .then((response) => response.json())
      .then((data) => {
        setDeals(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="deals-container">
      <h1>Previous Deals</h1>
      {isLoading ? (
        <LoadingDealGridView />
      ) : (
        <div class="deals-grid-container" id="player-deals">
          {deals.map((deal, index) => (
            <DealGridItemView
              key={index}
              item={deal}
              onClick={() => onClickItem(deal)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default DealsGridView;
