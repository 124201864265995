import SubscribeButton from "./SubscribeButton/SubscribeButton";
import SocialsStack from "./SocialsStack/SocialsStack";
import pp_profile from "../images/pp_profile.png";
import "./HeaderView.css";

const HeaderView = () => {
  return (
    <div className="header-view">
      <SubscribeButton />
      <img src={pp_profile} alt="Player's Punch secondary logo"></img>
      <SocialsStack />
    </div>
  );
};

export default HeaderView;
