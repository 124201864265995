import "./SubscribeButton.css";
import React from "react";
// import { ReactComponent as MailchimpIcon } from "../../images/mailchimp.svg";
import mailchimpIcon from "../../images/mailchimp-icon.png";

function SubscribeButton() {
  return (
    <div className="subscribe-button">
      <a href="https://mailchimp.com">
        <img src={mailchimpIcon} alt="Mailchimp Subscribe image"></img>
      </a>
    </div>
  );
  return <MailchimpIcon />;
  // <button class="subscribe-button">Subscribe</button>;
}

export default SubscribeButton;
