import "./SocialsStack.css";

function SocialsStack() {
  return (
    <div class="socials-stack">
      <a href="mailto:brandon@playerspunch.com?subject=Player&#39s Punch %26">
        <i
          class="fa fa-envelope-o fa-2x"
          aria-hidden="true"
          style={{ color: "white", paddingRight: 12 }}></i>
      </a>
      <a target="_blank" href="http://www.linkedin.com/in/brandoncurran/">
        <i
          class="fa fa-linkedin fa-2x"
          aria-hidden="true"
          style={{ color: "white", paddingRight: 12 }}></i>
      </a>
      <a target="_blank" href="http://www.instagram.com/playerspunch">
        <i
          class="fa fa-instagram fa-2x"
          aria-hidden="true"
          style={{ color: "white", paddingRight: 12 }}></i>
      </a>
    </div>
  );
}

export default SocialsStack;
